module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://matomo.go-indochine.at","siteUrl":"https://nguyen.reisen"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
